import React from 'react'
import Lottie from 'lottie-react'
import spinner from "./animations/loadingspinner.json"
export default function LoadingSpinner() {
    setTimeout(()=>{
        const spinner=document.querySelectorAll(".loadingspinner")
       spinner[0].classList.add("loadingspinnerremove")
    },5000)
  return (
    <div className='loadingspinner' id="spinner"><Lottie animationData={spinner}></Lottie></div>
  )
}
