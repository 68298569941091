import logo from './logo.svg';
import React,{lazy,Suspense} from 'react';
import './App.css';
import Navbar from "./components/Navbar"
import Topheader from './components/Topheader';
import Coursel from './components/Coursel';
import Backgroundfix from './components/Backgroundfix';
// import aaa from './aaa.jpg'
import navigationcompass from './navigationcompass.jpg'
import {BrowserRouter,Routes,Route,Link} from 'react-router-dom'
import About from './components/About';
import Allcmp from './components/About';
import Navbarnew from './components/Navbarnew'
import aaa from "./components/images/aaa.jpg"
import color from "./components/images/color2.jpg"
import simplicity2 from "./components/images/simplicity2.jpg"
import simplicity1 from "./components/images/simplebg.jpg"
import Dropdown from './components/Dropdown';
import Kkk from './components/Kkk';
import Login from './components/Login';
import AnimatedRoutes from './components/AnimatedRoutes';
import Lottie from 'lottie-react';
import ferrari from "./components/images/ferrarifast.mp4"
import navigateoverlay from "./components/images/navigate.png"
import caligraphy from "./components/images/caligraphy.jpg"

import Contactus from "./components/cnt.json"
// import Contactuslotte from "./components/Animation - 1705938213413.json"
import Contactuslotte from "./components/cnt.json"
import Lotteanimation from './components/Lotteanimation';
// import Infopane from './components/Infopane';
import LoadingSpinner from './components/LoadingSpinner';
import Features from './components/Features';
import Festures2 from './components/Festures2';

const Infopane= React.lazy(()=>import("./components/Infopane"))
const Icons=React.lazy(()=>import("./components/Icons"))
const OverlayHalf=React.lazy(()=>import("./components/OverlayHalf"))
const Animationdiv=React.lazy(()=>import("./components/Animationdiv"))  
const ContactPage =React.lazy(()=>import("./components/ContactPage"))
const Fadingcnt2=React.lazy(()=>import("./components/Fadingcnt2"))
const Fadingcnt =React.lazy(()=>import("./components/Fadingcnt"))



function App() {
  
  
  
  const courseldata=[{
    name:"responsive",
    discription:""
  }]
  const overlayhalfdata=[{
     name:"NAVIGATION",
     bgimg:navigationcompass,
     overlayimg:navigateoverlay,
     link:"https://th.bing.com/th/id/R.32079ff5fa0b188d51aa863b1d6736dc?rik=DzJgHg0VZn7BRw&pid=ImgRaw&r=0",
     discription:"Users will get easy navigation to the various components of the site and it is easy for them to find for which customer is looking for"
  },
  {
    name:"SIMPLICITY",
     bgimg:simplicity1,
     overlayimg:simplicity2,
     link:"https://th.bing.com/th/id/R.32079ff5fa0b188d51aa863b1d6736dc?rik=DzJgHg0VZn7BRw&pid=ImgRaw&r=0",
     discription:"Our webcontent and design are simple and easy to understandable for various types of users."
  },
  
]
  return (<>
  <LoadingSpinner/>
    <Topheader/>
    <BrowserRouter>
    
    <Navbarnew/>
    <AnimatedRoutes/>
    </BrowserRouter>
    <Coursel/>
    
    <Backgroundfix/>
    
    
    
    <Suspense fallback={<div>Loading...</div>}>
    <Fadingcnt img2={caligraphy} img1={color}/>
    <Fadingcnt2/>
    <Animationdiv/>
    <ContactPage/>
    <OverlayHalf OverlayHalfImg={overlayhalfdata}/>
        <Features/>
       
    <Infopane/>
    <Icons/>
      </Suspense>
  
    </>);
}

export default App;
